

import { Vue, Component, Watch } from 'vue-property-decorator'
import { DeviceBase } from '@/types/devices.d'
import { ElForm } from 'element-ui/types/form'

@Component
export default class DeviceAdd extends Vue {
  private dockModelList: Array<{ deviceTypeId: string; deviceTypeName: string; deviceModels: Array<{ modelKey: string; modelName: string }> }> = []
  private dockModelChildList: Array<{ modelKey: string; modelName: string }> = []
  private loading = false
  private deviceInfo: DeviceBase = {
    deviceName: '',
    deviceNumber: '',
    deviceTypeId: '',
    deviceModel: ''
  }

  private rules = {
    deviceTypeId: [
      { required: true, message: '请选择设备类型', trigger: 'change' }
    ],
    deviceModel: [
      { required: true, message: '请选择通讯型号', trigger: 'change' }
    ],
    deviceNumber: [
      { required: true, message: '请输入设备串号', trigger: 'change' }
    ],
    deviceName: [
      { required: true, message: '请输入设备名称', trigger: 'change' }
    ]
  }

  created () {
    this.getDockModelList()
  }

  // 获取设备类型列表
  getDockModelList () {
    this.$axios.get(this.$apis.devices.getDeviceTypeList).then((res) => {
      this.dockModelList = res.list || []
    })
  }

  // 设备类型修改
  deviceTypChange (val: string) {
    this.deviceInfo.deviceModel = ''
    if (val) {
      const index = this.dockModelList.findIndex(item => item.deviceTypeId === val)
      if (index >= 0) {
        // 通讯型号列表获取
        this.dockModelChildList = this.dockModelList[index].deviceModels
        // 设备名称默认设为设备类型名称
        this.deviceInfo.deviceName = this.dockModelList[index].deviceTypeName
      } else {
        this.dockModelChildList = []
        this.deviceInfo.deviceName = ''
      }
    } else {
      this.dockModelChildList = []
      this.deviceInfo.deviceName = ''
    }
  }

  addDevice () {
    this.loading = true
    const formData = JSON.parse(JSON.stringify(this.deviceInfo)) as DeviceBase
    this.$axios.post(this.$apis.devices.insertDevice, formData).then(() => {
      this.$message.success('设备添加成功')
      this.$router.back()
    }).finally(() => {
      this.loading = false
    })
  }

  submit (formName: string) {
    (this.$refs[formName] as ElForm).validate(valid => {
      if (valid) {
        this.addDevice()
      }
    })
  }
}
